import React from "react"
import {
  faClock,
  faCode,
  faCoins,
  faMobile,
  faCogs,
  faRetweet,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export default function Section2() {
  return (
    <div id="services">
      <h1 className="title title-blue">Professional Services</h1>
      <div className="services-section">
        <div
          class="card service-div"
          data-sal="slide-up"
          data-sal-delay="100"
          data-sal-easing="ease"
          data-sal-duration="1200"
        >
          <div class="card-icon">
            <FontAwesomeIcon icon={faRetweet} />
          </div>

          <div class="card-body">
            <h5 class="card-title">Automate</h5>
            <p class="card-text">
              It is all about Automation. Everything can and should be
              automated! Get to enjoy your business.
            </p>
            {/* <a href="#" class="btn btn-primary">
              Go somewhere
            </a> */}
          </div>
        </div>
        <div
          class="card service-div"
          data-sal="slide-up"
          data-sal-delay="200"
          data-sal-easing="ease"
          data-sal-duration="1200"
        >
          <div class="card-icon">
            <FontAwesomeIcon icon={faCogs} />
          </div>

          <div class="card-body">
            <h5 class="card-title">Integrate</h5>
            <p class="card-text">
              Integration is our specialty. Systems can really talk to each
              other over API calls. This is what we do all day.
            </p>
            {/* <a href="#" class="btn btn-primary">
              Go somewhere
            </a> */}
          </div>
        </div>
        <div
          class="card service-div"
          data-sal="slide-up"
          data-sal-delay="200"
          data-sal-easing="ease"
          data-sal-duration="1200"
        >
          <div class="card-icon">
            <FontAwesomeIcon icon={faCode} />
          </div>

          <div class="card-body">
            <h5 class="card-title">Clean and Secure Code</h5>
            <p class="card-text">
              Our systems are professional and slink. Our code is readable and
              ready to build upon based on your business needs.
            </p>
            {/* <a href="#" class="btn btn-primary">
              Go somewhere
            </a> */}
          </div>
        </div>
        <div
          class="card service-div"
          data-sal="slide-up"
          data-sal-delay="400"
          data-sal-easing="ease"
          data-sal-duration="1200"
        >
          <div class="card-icon">
            <FontAwesomeIcon icon={faCoins} />
          </div>

          <div class="card-body">
            <h5 class="card-title">Robust Systems</h5>
            <p class="card-text">
              Systems built by Sysdone are ready to cruise along with your
              business trajectory. It is built with your growth in mind.
            </p>
            {/* <a href="#" class="btn btn-primary">
              Go somewhere
            </a> */}
          </div>
        </div>
      </div>
    </div>
  )
}
